import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, combineLatest, of } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ApiService } from "./api.service";
import { LocalStorageService } from "./local-storage.service";
import { Translation } from "./translation.model";
import { Language } from "./menu-elements.model";
import { ActivatedRoute } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    active_language_id_subj: BehaviorSubject<number>;
    translations$: Observable<Translation[]>;
    languages$: Observable<Language[]>;
    languageCode$: Observable<string>;
    isCalendarEnabled$: Observable<boolean>;

    constructor(private localStorageService: LocalStorageService, private api: ApiService, private route: ActivatedRoute) {
        this.active_language_id_subj = new BehaviorSubject<number>(this.localStorageService.get('sugihara_active_language_id'));

        this.languages$ = this.api.getLanguages().pipe(shareReplay(1));
        this.isCalendarEnabled$ = this.api.getSetting('CALENDAR_ENABLED').pipe(shareReplay(1), map(x => x.value == 'true'));

        if (!this.active_language_id_subj.value) {
            this.languages$.subscribe(x => {
                const defLanguage = x.find(y => y.is_default);
                if (defLanguage) {
                    this.changeLanguage(defLanguage.id);    
                }
            });
        }

        this.translations$ = this.api.getTranslations().pipe(shareReplay({ bufferSize: 1, refCount: true }));
        this.languageCode$ = combineLatest([this.active_language_id_subj.asObservable(), this.languages$]).pipe(
            map(([languageId, languages]) => {
                if (!languages || languages.length === 0) {
                    return '';
                } 
                return languages.find(x => x.id == languageId)?.code.toLocaleLowerCase();
            })
        );
    }

    changeLanguage(id: number) {
        this.localStorageService.store('sugihara_active_language_id', id);
        this.active_language_id_subj.next(id);
    }

    getLanguage(): Observable<number> {
        return this.active_language_id_subj.asObservable().pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    setExternalPageLanguage(): void {
        this.active_language_id_subj.next(1);
    }

    getExternalPageLanguage(): Observable<number> {
        return of(1);
    }

    getLanguages(): Observable<Language[]> {
        return this.languages$;
    }

    getTranslations$(): Observable<Translation[]>{
        return this.translations$;
    }
}