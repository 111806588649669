export class EventRegistration {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    ticket_amount: number;
    total_price: number;
    status: RegistrationStatus;
    comment: string;
    event_id: number;
    event_date: Date;
    event_name: string;

    constructor(r: EventRegistrationResponse) {
        Object.assign(this, r);
        this.id = +r.id;
        this.total_price = +r.total_price;
        this.ticket_amount = +r.ticket_amount;
        this.event_id = +r.event_id;
        const eDate =  new Date(r.event_date);
        const sTime = new Date(r.from_time);
        this.event_date = new Date(eDate.getFullYear(), eDate.getMonth(), eDate.getDate(), sTime.getHours(), sTime.getMinutes(), 0);
    }
}

export class EventRegistrationResponse {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    ticket_amount: string;
    total_price: string;
    status: RegistrationStatus;
    comment: string;
    event_id: string;
    event_date: string;
    event_name: string;
    from_time: string;
}

export enum RegistrationStatus {
    Pending = '0',
    Approved = '1',
    Rejected = '2'
}

export const registrationStatusDescriptions = [
    { value: RegistrationStatus.Pending, text: 'Pending' },
    { value: RegistrationStatus.Approved, text: 'Approved' },
    { value: RegistrationStatus.Rejected, text: 'Rejected' }
];