import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    get<T>(key: string): T {
        return JSON.parse(localStorage.getItem(key));
    }

    store(key: string, value: any) {
        localStorage.setItem(key, value);
    }
}