export class EventRegistrationFilters {
    filters: SingleFilter[];

    constructor(r: EventRegistrationFiltersResponse) {
        this.filters = r.filters.map(x => {
            return {
                event_id: +x.event_id,
                name: x.name,
                day: x.date
            }
        })
    }
}

export class SingleFilter {
    event_id: number;
    name: string;
    day: Date;
}

export class EventRegistrationFiltersResponse {
    filters: SingleFilterResponse[];
}

export class SingleFilterResponse {
    event_id: string;
    name: string;
    date: Date;
}
