import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { combineLatest, Observable, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { ApiService } from "./api.service";
import { AuthorizationService } from "./authorization.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
    constructor(
        private api: ApiService, 
        private router: Router,
        private auth: AuthorizationService) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.auth.isAuthorized$.pipe(take(1),switchMap(x => {
            if (x) {
                return of(x);
            } else {
                const token = this.auth.authToken;
                if (token) {
                    return this.api.isAuthorized(this.auth.authToken.token).pipe(
                        take(1),
                        switchMap(x => this.auth.setIsAuthorized(x)),
                        switchMap(x => {
                            if (x) {
                                return of(x);
                            } else {
                                return this.router.navigate(['login']);
                            }
                        })
                     );
                } else {
                    return this.router.navigate(['login']);
                }
            }
        }));
    }
}