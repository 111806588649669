import { Pipe, PipeTransform } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedService } from './shared.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  constructor(private shared: SharedService) {

  }

  transform(value: unknown, ...args: unknown[]): Observable<string> {
    return combineLatest([this.shared.active_language_id_subj.asObservable(), this.shared.getTranslations$()])
      .pipe(map(([language_id, translations]) => {
        const translatedWord = translations.find(x => x.translation_code == value);
        if (translatedWord && translatedWord.translation) {
          const correctTranslation = translatedWord.translation.find(x => x.language_id == language_id);
          if (correctTranslation) {
            return correctTranslation.translation; 
          } else {
            return 'Translation not found';
          }
        } else {
          return 'Translation not found';
        }
      }));
  }

}
