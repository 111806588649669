import { Content } from "@angular/compiler/src/render3/r3_ast";
import { DateRange, DateSelectionModelChange } from "@angular/material/datepicker";

export class ContentHeader {
    id: number;
    start_date?: Date;
    end_date?: Date;
    name: string;
    type: ContentType;
    show_in_main_page: boolean;
    is_calendar_event: boolean;
    price: number;
    contents: Content[];
    calendar_contents: Content[];
    thumbnail: string;
    attachments: string[];
    number_of_columns: number;
    grid_elements: any[];
    ticket_template_id: number;
}

export enum ContentType {
    Article = 0,
    Event = 1,
    GridElement = 2,
    GridMenuElement = 3
}


export class EventByTime {
    id: number;
    content_header_id: number;
    date: Date;
    from_time: Date;
    to_time: Date;
    max_participants: number;
}


export class EventSchedule {
    price: number;
    schedule: DaySchedule[];
}

export class DaySchedule {
    date: Date;
    events_by_time: DayTimeSchedule[];

    constructor() {
        this.events_by_time = new Array<DayTimeSchedule>();
    }
}

export class DayTimeSchedule {
    id: number;
    from_time: Date;
    to_time: Date;
    max_participants: number;
}