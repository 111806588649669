// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    // production: false,
    // backendUrl: 'http://localhost/',
    // imagesLocation: 'http://localhost/images/',
    // templatesLocation: 'http://localhost/ticket-templates/',
    // apiUrl: 'http://localhost/api/'
    production: false,
    backendUrl: 'https://external-sugihara.eu/',
    imagesLocation: 'https://external-sugihara.eu/images/',
    apiUrl: 'https://external-sugihara.eu/api/',
    templatesLocation: 'https://external-sugihara.eu/ticket-templates/',
    // production: false,
    // backendUrl: 'https://sugiharahouse.com/',
    // imagesLocation: 'https://sugiharahouse.com/images/',
    // apiUrl: 'https://sugiharahouse.com/api/',
    // templatesLocation: 'https://sugiharahouse.com/ticket-templates/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
