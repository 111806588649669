import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map as obsMap, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from './authorization.service';
import { ContentHeader, DaySchedule, DayTimeSchedule, EventByTime } from './content-header.model';
import { Content } from './content.model';
import { Language, Menu, SingleGridElement } from './menu-elements.model';
import { MenuAndContents, SelfServiceEventElement } from './self-service-elements';
import { Setting } from './setting.model';
import { Translation } from './translation.model';
import { Utils } from './utils';
import * as _ from 'lodash-es';
import { CalendarEvent, CalendarEventRequestModel, CalendarEventInfo, GetCalendarEventInfoRequestModel } from './event-calendar.model';
import { formatDate } from '@angular/common';
import { EventTicket } from './event_ticket.model';
import { EventRegistration, EventRegistrationResponse } from './event-registration.model';
import { EventRegistrationFilters, EventRegistrationFiltersResponse } from './event-registration-filters.model';
import { TicketTemplate } from './ticket-template.model';
import { RichTextModel } from './rich-text.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiUrl: string;
  imageUrl: string;
  constructor(private http: HttpClient, 
    private authService: AuthorizationService,
    @Inject(LOCALE_ID) private locale: string) {
    this.apiUrl = environment.apiUrl;
    this.imageUrl = environment.imagesLocation;
  }

  getArticles(): Observable<ContentHeader[]> {
    return this.http.get<ContentHeader[]>(`${this.apiUrl}content/get_articles.php`)
      .pipe(obsMap(x => x.sort((a: ContentHeader, b: ContentHeader) => Number.parseInt(`${a.id}`) > Number.parseInt(`${b.id}`) ? -1 : 1 )));
  }

  getGridElements(): Observable<ContentHeader[]>{
    return this.http.get<ContentHeader[]>(`${this.apiUrl}content/get_grid_elements.php`)
      .pipe(
        obsMap(x => {
          x.forEach(y => y.id = Number.parseInt(`${y.id}`));
          return x;
        }),
        obsMap(x => x.sort((a: ContentHeader, b: ContentHeader) => Number.parseInt(`${a.id}`) > Number.parseInt(`${b.id}`) ? -1 : 1 ))
      );
  }

  getEvents(): Observable<ContentHeader[]>{
    return this.http.get<ContentHeader[]>(`${this.apiUrl}content/get_events.php`)
      .pipe(obsMap(x => x.sort((a: ContentHeader, b: ContentHeader) => Number.parseInt(`${a.id}`) > Number.parseInt(`${b.id}`) ? -1 : 1 )));
  }

  getGridMenuContents(): Observable<ContentHeader[]> {
    return this.http.get<ContentHeader[]>(`${this.apiUrl}content/get_grid_menu_elements.php`)
      .pipe(obsMap(x => x.sort((a: ContentHeader, b: ContentHeader) => Number.parseInt(`${a.id}`) > Number.parseInt(`${b.id}`) ? -1 : 1 )));
  }

  getMenuContent(): Observable<ContentHeader[]> {
    return this.http.get<ContentHeader[]>(`${this.apiUrl}content/get_menu_content.php`)
      .pipe(obsMap(x => x.sort((a: ContentHeader, b: ContentHeader) => Number.parseInt(`${a.id}`) > Number.parseInt(`${b.id}`) ? -1 : 1 )));
  }

  getEventRegistrations(): Observable<EventRegistration[]> {
    return this.http.get<EventRegistrationResponse[]>(`${this.apiUrl}event/get_registrations.php`)
    .pipe(
      obsMap(x => x.map(y => new EventRegistration(y))),
      obsMap(x => x.sort((a: EventRegistration, b: EventRegistration) => a.id > b.id ? -1 : 1 )),
    );
  } 

  getRegistrationFilters(): Observable<EventRegistrationFilters> {
    return this.http.get<EventRegistrationFiltersResponse>(`${this.apiUrl}event/get_registrations_filters.php`)
    .pipe(
      obsMap(x => new EventRegistrationFilters(x)),
      // obsMap(x => x.sort((a: EventRegistration, b: EventRegistration) => a.id > b.id ? -1 : 1 )),
    );
  }

  getContent(id: number): Observable<{contents: Content[], calendar_contents:Content[], header: ContentHeader, calendarEvents: DaySchedule[] }> {
    return this.buildContent(
      this.http.get<{contents: Content[], calendar_contents: Content[], header: ContentHeader, calendarEvents: EventByTime[]}>(`${this.apiUrl}content/get.php?id=${id}`)
    );  
  }

  getContentByName(name: string): Observable<{contents: Content[], header: ContentHeader, calendarEvents: DaySchedule[] }> {
    return this.buildContent(
      this.http.get<{contents: Content[], calendar_contents: Content[],header: ContentHeader, calendarEvents: EventByTime[]}>(`${this.apiUrl}content/get_by_name.php?name=${name}`)
    );
  }

  buildContent(content: Observable<{contents: Content[], calendar_contents: Content[], header: ContentHeader, calendarEvents: EventByTime[]}>): 
    Observable<{contents: Content[], calendar_contents: Content[], header: ContentHeader, calendarEvents: DaySchedule[] }> {
    return content.pipe(
      tap(x => Utils.fixContentDateFields(x.header)),
      tap(x => x.header.show_in_main_page = x.header.show_in_main_page as any === '1'),
      obsMap(x => {
        const obj: { contents: Content[], calendar_contents: Content[], header: ContentHeader, calendarEvents: DaySchedule[] } = { 
          contents: [],
          calendar_contents: [],
          header: null,
          calendarEvents: []
        };

        obj.header = x.header;
        obj.contents = x.contents;
        obj.calendar_contents = x.calendar_contents;

        try {
          obj.header.attachments = JSON.parse(obj.header.attachments as any);
        } catch(e) {
          obj.header.attachments = [];
        }

        try {
          obj.header.grid_elements = JSON.parse(obj.header.grid_elements as any);
        }catch(e) {
          obj.header.grid_elements = [];
        }

        try {
          obj.header.is_calendar_event = !!parseInt(`${obj.header.is_calendar_event}`);
        } catch (e) { }

        if (x.calendarEvents && x.calendarEvents.length > 0) {
          const resultDates = _.groupBy(x.calendarEvents, y => y.date);
          Object.keys(resultDates).forEach(date => {
            const events = resultDates[date];
            const daySchedule = new DaySchedule();
            daySchedule.date = new Date(date);
            daySchedule.events_by_time = events.map(y => {
              const dtSchedule = new DayTimeSchedule();
              dtSchedule.id = parseInt(y.id);
              dtSchedule.from_time = new Date(y.from_time);
              dtSchedule.to_time = new Date(y.to_time);
              dtSchedule.max_participants = parseInt(y.max_participants);
              return dtSchedule;
            });

            obj.calendarEvents.push(daySchedule);
          });
        }

        return obj;
      })
    );
  };

  getCalendarEvent(id: number): Observable<{contents: Content[], header: ContentHeader, event_info: { max_participants: number, currently_registered: number, event_date: Date, to_time: Date} }> {
    return this.http.get<{contents: Content[], header: ContentHeader, event_info: any}>
      (`${this.apiUrl}content/get_calendar_event.php?id=${id}`)
      .pipe(
        tap(x => Utils.fixContentDateFields(x.header)),
        tap(x => x.header.show_in_main_page = x.header.show_in_main_page as any === '1'),
        obsMap(x => {
          x.event_info.max_participants = Number.parseInt(x.event_info.max_participants);
          x.event_info.currently_registered = Number.parseInt(x.event_info.currently_registered);
          const day = Utils.fromUtcToLocal(x.event_info.date);
          const time = Utils.fromUtcToLocal(x.event_info.from_time);
          x.event_info.event_date = new Date(day.getFullYear(), day.getMonth(), day.getDate(), time.getHours(), time.getMinutes());
          const endTime = Utils.fromUtcToLocal(x.event_info.to_time);
          x.event_info.to_time = new Date(day.getFullYear(), day.getMonth(), day.getDate(), endTime.getHours(), endTime.getMinutes());

          const obj: { contents: Content[], header: ContentHeader, 
            event_info: {max_participants: number, currently_registered: number, event_date: Date, to_time: Date } } = { 
            contents: [],
            header: null,
            event_info: x.event_info
          };

          obj.header = x.header;
          obj.contents = x.contents;

          try {
            obj.header.attachments = JSON.parse(obj.header.attachments as any);
          } catch(e) {
            obj.header.attachments = [];
          }

          try {
            obj.header.grid_elements = JSON.parse(obj.header.grid_elements as any);
          }catch(e) {
            obj.header.grid_elements = [];
          }

          return obj;
        })
      );
  }

  getCalendarEventPopupInfo(eventId: number, languageId: number): Observable<CalendarEventInfo> {
    return this.http.get<GetCalendarEventInfoRequestModel>(`${this.apiUrl}content/get_calendar_event_info.php?id=${eventId}&languageId=${languageId}`)
      .pipe(obsMap(x => new CalendarEventInfo(x)));
  }

  createContent(formData: FormData): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}content/post.php`, formData, {
      headers: { Token: this.authService.authToken?.token }
    });
  }

  editContent(content_header_id: number, content: FormData): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}content/edit.php?id=${content_header_id}`, content, {
      headers: { Token: this.authService.authToken?.token }
    });
  }

  deleteContent(content_header_id: number): Observable<{ success: boolean, reason: string }> {
    return this.http.post<{ success: boolean, reason: string }>(`${this.apiUrl}content/delete.php?id=${content_header_id}`, {}, {
      headers: { Token: this.authService.authToken?.token }
    });
  }

  getLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(`${this.apiUrl}language/get_all.php`)
      .pipe(
        tap(x => x.forEach(y => y.is_default = y.is_default as any == '1'))
      );
  }

  // MENU
  createMenu(menu: Menu): Observable<any> {
    return this.http.post<number>(`${this.apiUrl}menu/post.php`, JSON.stringify(menu),{
      headers: { Token: this.authService.authToken?.token }
    });
  }

  editMenu(id: number, menu: Menu): Observable<any> {
    return this.http.post(`${this.apiUrl}menu/edit.php?id=${id}`, JSON.stringify(menu), {
      headers: { Token: this.authService.authToken?.token }
    });
  }

  getActiveMenus(): Observable<MenuAndContents> {
    return this.http.get<MenuAndContents>(`${this.apiUrl}menu/get_active.php`)
    .pipe(
      tap(x => x.menu.forEach(y => y.menu_structure = JSON.parse(`${y.menu_structure}`)))
    );
  }

  getAllMenus(): Observable<Menu[]> {
    return this.http.get<Menu[]>(`${this.apiUrl}menu/get_all.php`, {
      headers: { Token: this.authService.authToken?.token }
    }).pipe(
      tap(x => x.forEach(menu => menu.is_active = `${menu.is_active}` === '1'))
    );
  }

  getMenu(id: number): Observable<Menu> {
    return this.http.get<Menu>(`${this.apiUrl}menu/get.php?id=${id}`, {
      headers: { Token: this.authService.authToken?.token }
    }).pipe(
      tap(x => x.menu_structure = JSON.parse(x.menu_structure as any)),
      tap(x => x.is_active = x.is_active as any == '1' ? true : false)
    );
  }

  // Translations
  getTranslations(): Observable<Translation[]>{
    return this.http.get<Translation[]>(`${this.apiUrl}translation/get_all.php`).pipe(
      obsMap(x => {
        if (x) {
          x.forEach(y => {
            try {
              y.translation = JSON.parse(y.translation as any);
            } catch {
              console.log('error', y);
            }
          });
          return x;
        } else {
          return [];
        }
      })
    );
  }

  editTranslation(id: number, translation: Translation) {
    return this.http.post(`${this.apiUrl}translation/edit.php?id=${id}`, JSON.stringify(translation), {
      headers: { token: this.authService.authToken?.token }
    });
  }

  // Settings

  setSetting(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}settings/set.php`, formData, {
      headers: { Token: this.authService.authToken?.token }
    });
  }

  getSetting(key: string): Observable<Setting> {
    return this.http.get<Setting>(`${this.apiUrl}settings/get.php?key=${key}`);
  }

  testHeaders(): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}test_headers.php`, null, {
      headers: { Token: this.authService.authToken?.token }
    });
  }

  // SS specific

  getEventsForSS(): Observable<SelfServiceEventElement[]>{
    return this.http.get<SelfServiceEventElement[]>(`${this.apiUrl}content/get_events_ss.php`).pipe(
      tap(x => x.forEach(y => { 
        y.start_date = Utils.fromUtcToLocal(y.start_date);
        y.end_date = Utils.fromUtcToLocal(y.end_date);
      }))
    );
  }

  isAuthorized(authToken: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}is_authorized.php`, {
      headers: { Token: authToken }
    }).pipe(
      catchError(x => of(false))
    );
  }

  login(username: string, password: string): Observable<{ token: string }> {
    return this.http.post<{ token: string }>(`${this.apiUrl}authenticate.php`, { username, password })
      .pipe(obsMap(x => x));
  }

  getMainPageArticles(): Observable<SelfServiceEventElement[]>{
    return this.http.get<SelfServiceEventElement[]>(`${this.apiUrl}content/get_main_page_articles.php`);
  }

  getArticleGridElements(contentHeaderId: number, languageId: number): Observable<SingleGridElement[]> {
    return this.http.get<{ id: string, thumbnail: string, title: string }[]>
      (`${this.apiUrl}content/get_article_grid_elements.php?id=${contentHeaderId}&language_id=${languageId}`)
      .pipe(obsMap(x => {
        return x.map(y => ({ id: Number.parseInt(y.id), thumbnail: `${this.imageUrl}${y.thumbnail}`, title:y.title }));
      }));
  }

  getEventCalendar(languageId: number, date: Date, toDate: Date, contentHeaderId: number = 0): Observable<CalendarEvent[]> {
    const dateStr = formatDate(Math.max(date.getTime(), new Date().getTime()), 'yyyy-MM-dd', this.locale);
    const untilDateStr = formatDate(toDate.getTime(), 'yyyy-MM-dd', this.locale);
    return this.http.get<CalendarEventRequestModel[]>(`${this.apiUrl}content/get_event_calendar.php?language_id=${languageId}&date=${dateStr}&until_date=${untilDateStr}&content_header_id=${contentHeaderId}`)
    .pipe(obsMap(x => {
      const s = x.map(y => new CalendarEvent().build(y));
      return s
        .filter(e => e.remaining_tickets > 0)
        .sort((e1: CalendarEvent, e2: CalendarEvent) => e1.from_time > e2.from_time ? 1 : -1);
    }));
  }

  purchase(eventId: number, registration: EventTicket, language: string, isExternal: boolean = false): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}event/purchase.php?id=${eventId}`, { registration, language, isExternal });
  }

  approve(registrationId: number): Observable<{ Error: string }> {
    return this.http.post<any>(`${this.apiUrl}event/approve.php?id=${registrationId}`, null, { 
      headers: { Token: this.authService.authToken?.token } 
    });
  }

  selfServiceApprove(registrationId: number): Observable<{ Error: string }> {
    return this.http.get<any>(`${this.apiUrl}event/ss_approve.php?id=${registrationId}`);
  }

  reject(registrationId: number): Observable<{ Error: string }> {
    return this.http.post<any>(`${this.apiUrl}event/reject.php?id=${registrationId}`, null);
  }

  createTemplateTicket(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}ticket_template/post.php`, formData, {
      headers: { Token: this.authService.authToken?.token }
    })
  }

  editTemplateTicket(id: number, formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}ticket_template/edit.php?id=${id}`, formData, {
      headers: { Token: this.authService.authToken?.token }
    })
  }

  getAllTemplates(): Observable<TicketTemplate[]>{
    return this.http.get<any>(`${this.apiUrl}ticket_template/get_all.php`)
      .pipe(obsMap(templates => templates.map(x => new TicketTemplate(x))));
  }

  getTicketTemplate(id: number): Observable<TicketTemplate> {
    return this.http.get<any>(`${this.apiUrl}ticket_template/get.php?id=${id}`)
      .pipe(obsMap(x => new TicketTemplate(x)));
  }
  
  getRichTextContent(type: string): Observable<RichTextModel[]>{
    return this.http.get<RichTextModel[]>(`${this.apiUrl}rich_text/get.php?type=${type}`);
  }

  editRichRextContent(contents: RichTextModel[]): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}rich_text/edit.php`, { contents }, { headers: { Token: this.authService.authToken?.token } });
  }
}
