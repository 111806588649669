import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './shared/auth-guard.service';

const routes: Routes = [
    {
        path: 'admin',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'external',
        loadChildren: () => import('./external-components/external-components.module').then(m => m.ExternalComponentsModule)
    },
    {
        path: ':language',
        loadChildren: () => import('./self-service/self-service.module').then(m => m.SelfServiceModule)
    },
    {
        path: '',
        loadChildren: () => import('./self-service/self-service.module').then(m => m.SelfServiceModule)
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
