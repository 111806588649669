import { Utils } from "./utils";

export class CalendarEventRequestModel {
    id: string;
    content_header_id: string;
    date: string;
    from_time: string;
    to_time: string;
    remaining_tickets: string;
    title: string;
}

export class CalendarEvent {
    id: number;
    content_header_id: number;
    date: string;
    from_time: Date;
    to_time: Date;
    remaining_tickets: number;
    title: string;

    day: number;
    month: number;

    build(e: CalendarEventRequestModel): CalendarEvent {
        this.id = parseInt(e.id);
        this.date = e.date;
        this.from_time = Utils.getLocalTimeFromUtc(e.from_time);
        this.to_time = Utils.getLocalTimeFromUtc(e.to_time);
        this.remaining_tickets = e.remaining_tickets ? parseInt(e.remaining_tickets) : 0;
        this.title = e.title;
        this.content_header_id = parseInt(e.content_header_id);
        const d = new Date(e.date);
        this.day = d.getDate();
        this.month = d.getMonth();
        return this;
    }
}

export class CalendarBlock {
    day?: number;
    events?: CalendarEvent[];
    isBlank: boolean;
}

export class GetCalendarEventInfoRequestModel {
    content: string;
    content_header_id: string;
    currently_registered: string;
    max_participants: string;
    date: Date;
    from_time: Date;
    to_time: Date;
    thumbnail: string;
    title: string;
    price: string;
}

export class CalendarEventInfo {
    content: string;
    content_header_id: number;
    currently_registered: number;
    max_participants: number;
    date: Date;
    from_time: Date;
    to_time: Date;
    thumbnail: string;
    title: string;
    tickets_available: number;
    price: number;
    
    constructor(req: GetCalendarEventInfoRequestModel) {
        Object.assign(this, req);
        this.content_header_id = parseInt(req.content_header_id);
        this.currently_registered = parseInt(req.currently_registered);
        this.max_participants = parseInt(req.max_participants);
        this.tickets_available = this.max_participants - this.currently_registered;
        this.price = parseFloat(req.price);
    }
}