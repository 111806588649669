import { Injectable, OnInit } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
    'providedIn': 'root'
})
export class AuthorizationService implements OnInit {
    private authorized$: Observable<boolean>;
    private isAuthorizedSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private localStorage: LocalStorageService){
        this.authorized$ = this.isAuthorizedSubj.asObservable().pipe(shareReplay({ bufferSize: 1, refCount: true }))
    }

    ngOnInit() {
    }

    setIsAuthorized(isAuthorized: boolean) {
        this.isAuthorizedSubj.next(isAuthorized);
        return this.authorized$;
    }

    get isAuthorized$(): Observable<boolean> {
        return this.authorized$;
    }

    get authToken(): { token: string } {
        return this.localStorage.get<{ token: string }>('user_token');
    }
}