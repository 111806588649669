import { ContentHeader } from "./content-header.model";

export class Utils {
    static fixContentDateFields(content: ContentHeader): ContentHeader {
        content.start_date = this.fromUtcToLocal(content.start_date as any);
        content.end_date = this.fromUtcToLocal(content.end_date as any);
        return content;
    }

    static fromUtcToLocal(utcDate: string| Date): Date {
        if (!utcDate) {
            return null;
        }

        if (typeof utcDate === 'string' && utcDate && utcDate[utcDate.length - 1] != 'Z') {
            utcDate += 'Z';
        }
        const d = new Date(utcDate);
        return d;
    }

    static asFormData(form: any, singleFileFields: string[] = [], multiFileFields: string[] = []): FormData {
        const formData = new FormData();
        Object.keys(form).forEach(key => {
          if (singleFileFields.indexOf(key) >= 0 && form[key]) {
            formData.append(key, form[key], form[key].name);
          } else if (multiFileFields.indexOf(key) >= 0) {
              const files: FileList = form[key];
              if (files) {
                  for(let i = 0; i < files.length; i++) {
                    formData.append(key + '[]', files.item(i), files.item(i).name);
                  }
              }
          } else {
            if (form[key] && typeof form[key] === 'object') {
              formData.append(key, JSON.stringify(form[key]));
            } else {
              formData.append(key, (form[key]));
            }
          }
        });
        return formData;
      }

    static getTimePartWithoutSeconds(date: string): string {
      if (!date) {
        return '';
      }

      const localDateStr = new Date(date).toISOString();
      const timeProjection = localDateStr.split('T')[1];
      const timeParts = timeProjection.split(':');
      return `${timeParts[0]}:${timeParts[1]}`;
    }

    static getLocalTimeFromUtc(date: string): Date {
      if (!date) {
        return null;
      }

      return new Date(date);
    }
}