export class TicketTemplate {
    id: number;
    name: string;
    draw_on_ticket: boolean;
    parameters: TicketTemplateParameter[];
    ticket_file_path: string;
    templates_by_language: TicketTemplateContent[] = [];

    constructor(request: any) {
        Object.assign(this, request);

        this.draw_on_ticket = !!Number.parseInt(request.draw_on_ticket);
        this.id = Number.parseInt(request.id);
        this.parameters = JSON.parse(request.parameters);
        this.templates_by_language.forEach(t => {
            try {
                t.message_on_ticket = JSON.parse(`${t.message_on_ticket}`)
            } catch {}
        });
    }
}

export class TicketTemplateParameter {
    text_to_draw: string;
    top: number;
    left: number;
    color: string;
    font_size: number;
}

export class TicketTemplateContent {
    id: number;
    ticket_template_id: number;
    language_id: number;
    message_on_ticket: string[];
    email_template: string;
    type: TicketTemplateType;
}

export enum TicketTemplateType {
    RegistrationSucceess = 0,
    RegistrationApproved = 1,
    ChangedEventTime = 2
}

export const ticketTemplateDescriptions = [
    { value: TicketTemplateType.RegistrationSucceess,  text: 'Registration success template' },
    { value: TicketTemplateType.RegistrationApproved, text: 'Registration approved template' } ,
    { value: TicketTemplateType.ChangedEventTime, text:  'Changed event template' }
];